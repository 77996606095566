export enum EBackofficeModules {
  LOYALTY_CARDS = 'loyaltyCards',
  LOYALTY_CARDS_REWARDS = 'loyaltyCards_rewards',
  LOYALTY_CARDS_ORDERS = 'loyaltyCards_orders',
  LOYALTY_CARDS_CAMPAIGNS = 'loyaltyCards_campaigns',

  APP_MOBILE = 'appMobile',
  APP_MOBILE_ACCOUNTS = 'appMobile_accounts',
  APP_MOBILE_NEWS = 'appMobile_news',
  APP_MOBILE_FLYERS = 'appMobile_flyers',
  APP_MOBILE_NOTIFICATIONS = 'appMobile_notifications',

  GIFT_CARDS = 'giftCards',
}

export const OrganizationModulesInfo = [
  //// MODULO LOYALTY CARDS ////
  {
    id: EBackofficeModules.LOYALTY_CARDS,
    description: 'Modulo carte fedeltà',
  },
  {
    id: EBackofficeModules.LOYALTY_CARDS_REWARDS,
    description: 'Gestione premi',
    parent: EBackofficeModules.LOYALTY_CARDS,
  },
  {
    id: EBackofficeModules.LOYALTY_CARDS_ORDERS,
    description: 'Gestione ordini',
    parent: EBackofficeModules.LOYALTY_CARDS,
  },
  {
    id: EBackofficeModules.LOYALTY_CARDS_CAMPAIGNS,
    description: 'Gestione campagne punti',
    parent: EBackofficeModules.LOYALTY_CARDS,
  },

  //// MODULO APP ////

  {
    id: EBackofficeModules.APP_MOBILE,
    description: 'Modulo app mobile',
  },
  {
    id: EBackofficeModules.APP_MOBILE_ACCOUNTS,
    description: 'Gestione accounts',
    parent: EBackofficeModules.APP_MOBILE,
  },
  {
    id: EBackofficeModules.APP_MOBILE_NEWS,
    description: 'Gestione news',
    parent: EBackofficeModules.APP_MOBILE,
  },
  {
    id: EBackofficeModules.APP_MOBILE_FLYERS,
    description: 'Gestione volantini',
    parent: EBackofficeModules.APP_MOBILE,
  },
  {
    id: EBackofficeModules.APP_MOBILE_NOTIFICATIONS,
    description: 'Gestione notifiche',
    parent: EBackofficeModules.APP_MOBILE,
  },

  //// MODULO GIFT CARDS ////
  {
    id: EBackofficeModules.GIFT_CARDS,
    description: 'Modulo gift cards',
  },
];

export const OrganizationModulesDefault = {
  loyaltyCards: true,
  loyaltyCards_rewards: true,
  loyaltyCards_orders: true,
  loyaltyCards_campaigns: true,
  //
  giftCards: false,
  //
  appMobile: false,
  appMobile_accounts: false,
  appMobile_news: false,
  appMobile_flyers: false,
  appMobile_notifications: false,
};
