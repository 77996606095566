import { Pipe, PipeTransform } from '@angular/core';
import { ENotificationStatus, NotificationStatusDescriptions } from '@loyx/common';

@Pipe({
  name: 'notificationStatus',
})
export class NotificationStatusPipe implements PipeTransform {
  transform(statusId: ENotificationStatus | string, ...args: unknown[]): unknown {
    if (!statusId) return '';

    const status = NotificationStatusDescriptions.find((u) => u.id === statusId);

    return status ? status.description : statusId;
  }
}
