import { DbResourceEntity } from './db.entity';
import { EBarcodeType, IIntegrationInfo } from '../constants';
import { CampaignEntity } from './campaign.entity';
import { MediaEntity } from './media.entity';

export class OrganizationEntity extends DbResourceEntity {
  constructor(entityData?: Omit<OrganizationEntity, keyof DbResourceEntity>) {
    super();

    if (entityData) Object.assign(this, entityData);
  }

  /** */
  description: string;
  /** */
  settings?: SettingsEntity;
  /** */
  integrations?: IntegrationsEntity;

  modules?: OrganizationModulesEntity;
}

export class SettingsEntity {
  general?: SettingsGeneralEntity;
  cards?: SettingsCardsEntity;
  administration?: AdministrationSettingsEntity;
  graphics?: GraphicsSettingsEntity;

  // privacy?: SettingsPrivacyEntity;
}

export class SettingsGeneralEntity {
  /** Notifiche email */
  notifications?: boolean;
}

export class GraphicsSettingsEntity {
  logoId?: string;
  logoPdfId?: string;
  logoPdfBase64?: string;

  readonly logo?: MediaEntity;
  readonly logoPdf?: MediaEntity;
}

export class AdministrationSettingsEntity {
  /** Abilitazione commerciale */
  storesContractManagementEnabled?: boolean;
}

export class OrganizationModulesEntity {
  loyaltyCards: boolean;
  loyaltyCards_rewards: boolean;
  loyaltyCards_orders: boolean;
  loyaltyCards_campaigns: boolean;
  appMobile: boolean;
  appMobile_accounts: boolean;
  appMobile_news: boolean;
  appMobile_flyers: boolean;
  appMobile_notifications: boolean;
  giftCards: boolean;
}

export class SettingsCardsEntity {
  /** Circolarità attiva sull'organizzazione. Se non attiva le tessere sono valide sono nel negozio o gruppo negozi di appartenza */
  circularity?: boolean;
  /** Generazione barcode automatica */
  barcodeGeneration?: boolean;
  /** Tipologia del barcode */
  barcodeType?: EBarcodeType;
  /** Prefisso barcode */
  barcodePrefix?: number;

  // /** Tipo di generazione del codice */
  // codeGeneration?: ECardCodeGenerationType;
  // /** Tipologia del codice carte */
  // codeType?: ECardCodeType;
  // // codeGenerationRules: {}
}

// export class SettingsPrivacyEntity {
//   policies: {
//     version: number;
//     type: string; // text/editor - url
//     text?: string;
//     url?: string;
//   }[];
//   agreements: {
//     _id: string;
//     type: string; // tos - marketing - targeted - third_party
//   }[];
// }

export class IntegrationConfigEntity {
  code: string;
  active: boolean;
  info?: IIntegrationInfo;
}

export class SistoreIntegrationConfigEntity extends IntegrationConfigEntity {
  mainCampaignId?: string;
  validateBarcode?: boolean;

  readonly mainCampaign?: CampaignEntity;
}

export class PrassiMagicIntegrationConfigEntity extends IntegrationConfigEntity {
  mainCampaignId?: string;
  validateBarcode?: boolean;

  readonly mainCampaign?: CampaignEntity;
}

export class IntegrationsEntity {
  sistore?: SistoreIntegrationConfigEntity;
  prassiMagic?: PrassiMagicIntegrationConfigEntity;
}
