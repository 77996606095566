import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NbDialogService } from '@nebular/theme';

import { IBackendQueryPagination } from '../../../@core/utils/backend.service';
import { MediaChooserComponent } from '../../../backoffice/medias/media-chooser/media-chooser.component';
import { IMedia } from '@loyx/common';

@Component({
  selector: 'media-picker',
  templateUrl: './media-picker.component.html',
  styleUrls: ['./media-picker.component.scss'],
})
export class MediaPickerComponent {
  @Input() mediaFromParent: IMedia;
  @Input() typesAllowed: string[];
  @Input() multiFiles: boolean = false;
  @Input() multiSelect: boolean = false;
  @Input() enableReSelect: boolean = true;
  @Output() selectedImageOutput = new EventEmitter();

  pagination: IBackendQueryPagination = {
    perpage: 10,
    page: 1,
  };

  constructor(private dialogService: NbDialogService) {}

  openDialog() {
    this.dialogService
      .open(MediaChooserComponent, {
        dialogClass: 'dialog-container-lg',
        closeOnBackdropClick: false,
        closeOnEsc: false,
        context: {
          mediaFromParent: this.mediaFromParent,
          typesAllowed: this.typesAllowed,
          multiFiles: this.multiFiles,
          multiSelect: this.multiSelect,
        },
      })
      .onClose.subscribe((images) => {
        this.mediaFromParent = images;
        if (Array.isArray(this.mediaFromParent)) {
          this.selectedImageOutput.emit(this.mediaFromParent);
        } else if (this.mediaFromParent) {
          this.selectedImageOutput.emit(this.mediaFromParent);
        }
      });
  }

  reset() {
    this.mediaFromParent = null;
    this.selectedImageOutput.emit();
  }
}
