import { Pipe, PipeTransform } from '@angular/core';

import { EOrderStatus, OrderStatuses } from '@loyx/common';

@Pipe({
  name: 'orderStatus',
})
export class OrderStatusPipe implements PipeTransform {
  transform(statusId: EOrderStatus | string, ...args: unknown[]): unknown {
    if (!statusId) return '';

    const status = OrderStatuses.find((u) => u.id === statusId);

    return status ? status.description : statusId;
  }
}
