import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { NbAccessChecker } from '@nebular/security';
import { lastValueFrom } from 'rxjs';

import { OrganizationModulesInfo, ResourceDescription } from '@loyx/common';

import { AccountService } from '../../@core/utils';

@Directive({
  selector: '[hasPermission]',
})
export class hasPermissionDirective implements OnInit {
  @Input('hasPermission') requestedPermission: string[];
  @Input('hideIfHasPermission') hideIfHasPermission?: boolean = false;

  constructor(
    private elementRef: ElementRef,
    private accessChecker: NbAccessChecker,
    private account: AccountService
  ) {}

  ngOnInit(): void {
    this.hasPermissionCheck();
  }

  async hasPermissionCheck() {
    const permission = this.requestedPermission[0];
    const resource = this.requestedPermission[1];

    if (!permission || !resource) return;

    let hasPermission = await lastValueFrom(this.accessChecker.isGranted(permission, resource));

    const currentResource = ResourceDescription.find(r => r.id == resource);

    if (hasPermission && currentResource?.module) {
      const orgModules = this.account.getOrganization()?.modules;
      const currentModule = OrganizationModulesInfo.find(m => m.id == currentResource.module);

      if (currentModule && (!orgModules[currentModule.id] || (currentModule.parent && !orgModules[currentModule.parent])))
        hasPermission = false;
    }

    this.elementRef.nativeElement.style.display = (!hasPermission || this.hideIfHasPermission) ? 'none' : '';
  }
}
