import { Pipe, PipeTransform } from '@angular/core';

import { ENewsStatus, NewsStatusDescriptions } from '@loyx/common';

@Pipe({
  name: 'newsStatus',
})
export class NewsStatusPipe implements PipeTransform {
  transform(statusId: ENewsStatus | string, ...args: unknown[]): unknown {
    if (!statusId) return '';

    const active = NewsStatusDescriptions.find((u) => u.id === statusId);

    return active ? active.description : statusId;
  }
}
