import { EBackofficeModules } from './modules.constants';

export enum EUserType {
  ADMIN_USER = 'admin',
  ORGANIZATION_USER = 'user',
}

export enum EUserRole {
  SUPER_ADMIN = 'super_admin',
  HELP_DESK = 'help_desk',
  SALES_MANAGER = 'sales_manager',
  //
  ORGANIZATION_MANAGER = 'organization_manager',
  STORE_OWNER = 'store_owner',
  STORE_MANAGER = 'store_manager',
  STORE_CLERK = 'store_clerk',
}

export enum EBackofficeResource {
  ORGANIZATIONS = 'organizations',
  ADMIN_USERS = 'admin_users',
  AUTH = 'auth',
  //
  ORGANIZATION_USERS = 'org_users',
  ORGANIZATION_STORES = 'org_stores',
  ORGANIZATION_LOYALTY_CARDS = 'org_loyaltycards',
  ORGANIZATION_ACCOUNTS = 'org_accounts',
  ORGANIZATION_REWARDS = 'org_rewards',
  ORGANIZATION_ORDERS = 'org_orders',
  ORGANIZATION_CAMPAIGNS = 'org_campaigns',
  ORGANIZATION_GIFTCARDS = 'org_giftcards',
  ORGANIZATION_NEWS = 'org_news',
  ORGANIZATION_SETTINGS = 'org_settings',
  ORGANIZATION_ADMINISTRATION_SETTINGS = 'org_administration_settings',
  ORGANIZATION_GIFTCARDS_TRANSACTIONS = 'org_giftcards_transactions',
  ORGANIZATION_LOYALTY_CARDS_TRANSACTIONS = 'org_loyaltycards_transactions',
  ORGANIZATION_GIFTCARD_UNLOCK = 'org_giftcard_unlock',
  ORGANIZATION_GIFTCARD_CODE = 'org_giftcard_code',
  ORGANIZATION_LOGS = 'org_logs',
  ORGANIZATION_CARDS_TYPE = 'org_cards_type',
  ORGANIZATION_MEDIAS = 'org_medias',
  ORGANIZATION_FLYERS = 'org_flyers',
  ORGANIZATION_NOTIFICATIONS = 'org_notifications',
}

export const ResourceDescription: {
  id: EBackofficeResource;
  description: string;
  path?: string;
  module?: EBackofficeModules;
  viewOnFilterLogList?: boolean;
}[] = [
    {
      id: EBackofficeResource.ORGANIZATION_USERS,
      description: 'Utenti',
    },
    {
      id: EBackofficeResource.ORGANIZATION_STORES,
      description: 'Negozi',
    },
    {
      id: EBackofficeResource.ORGANIZATION_LOYALTY_CARDS,
      description: 'Carte fedeltà',
      path: 'carte',
      module: EBackofficeModules.LOYALTY_CARDS,
    },
    {
      id: EBackofficeResource.ORGANIZATION_ACCOUNTS,
      description: 'Account',
      path: 'accounts',
      module: EBackofficeModules.APP_MOBILE_ACCOUNTS,
    },
    {
      id: EBackofficeResource.ORGANIZATION_REWARDS,
      description: 'Premi',
      path: 'premi',
      module: EBackofficeModules.LOYALTY_CARDS_REWARDS,
    },
    {
      id: EBackofficeResource.ORGANIZATION_ORDERS,
      description: 'Richieste premi',
      path: 'ordini',
      module: EBackofficeModules.LOYALTY_CARDS_ORDERS,
    },
    {
      id: EBackofficeResource.ORGANIZATION_CAMPAIGNS,
      description: 'Campagne punti',
      path: 'campagne',
      module: EBackofficeModules.LOYALTY_CARDS_CAMPAIGNS,
    },
    {
      id: EBackofficeResource.ORGANIZATION_GIFTCARDS,
      description: 'Gift cards',
      path: 'giftcards',
      module: EBackofficeModules.GIFT_CARDS,
    },
    {
      id: EBackofficeResource.ORGANIZATION_NEWS,
      description: 'News',
      path: 'news',
      module: EBackofficeModules.APP_MOBILE_NEWS,
    },
    {
      id: EBackofficeResource.ORGANIZATION_SETTINGS,
      description: 'Impostazioni',
      /// TODO ///
      path: 'impostazioni/agreements',
    },
    {
      id: EBackofficeResource.ORGANIZATION_CARDS_TYPE,
      description: 'Tipi carte fedeltà',
    },
    {
      id: EBackofficeResource.ORGANIZATION_GIFTCARDS_TRANSACTIONS,
      description: 'Transazioni gift cards',
      module: EBackofficeModules.GIFT_CARDS,
    },
    {
      id: EBackofficeResource.ORGANIZATION_LOYALTY_CARDS_TRANSACTIONS,
      description: 'Transazioni carte fedeltà',
      module: EBackofficeModules.LOYALTY_CARDS,
    },
    {
      id: EBackofficeResource.ORGANIZATION_MEDIAS,
      description: 'Media',
      path: 'medias',
    },
    {
      id: EBackofficeResource.ORGANIZATION_FLYERS,
      description: 'Volantini',
      path: 'flyers',
      module: EBackofficeModules.APP_MOBILE_FLYERS,
    },
    {
      id: EBackofficeResource.ORGANIZATION_NOTIFICATIONS,
      description: 'Notifiche',
      path: 'notifiche',
      module: EBackofficeModules.APP_MOBILE_NOTIFICATIONS,
    },
    {
      id: EBackofficeResource.ORGANIZATION_GIFTCARD_UNLOCK,
      description: 'Sblocco gift card',
      viewOnFilterLogList: false,
    },
    {
      id: EBackofficeResource.ORGANIZATION_LOGS,
      description: 'Logs',
      viewOnFilterLogList: false,
    },
    {
      id: EBackofficeResource.ADMIN_USERS,
      description: 'Utenti Admin',
      viewOnFilterLogList: false,
    },
    {
      id: EBackofficeResource.ORGANIZATIONS,
      description: 'Organizzazione',
      viewOnFilterLogList: false,
    },
    {
      id: EBackofficeResource.AUTH,
      description: 'Autenticazione',
      viewOnFilterLogList: false,
    },
    {
      id: EBackofficeResource.ORGANIZATION_GIFTCARD_CODE,
      description: 'Codice gift card',
      viewOnFilterLogList: false,
    },
  ];

export enum EBackofficeUserPermission {
  MANAGE = 'manage',
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  LOGIN = 'login',
}

export const PermissionDescription = [
  {
    id: EBackofficeUserPermission.CREATE,
    description: 'Creazione',
  },
  {
    id: EBackofficeUserPermission.DELETE,
    description: 'Cancellazione',
  },
  {
    id: EBackofficeUserPermission.READ,
    description: 'Lettura',
    viewOnFilterLogList: false,
  },
  {
    id: EBackofficeUserPermission.UPDATE,
    description: 'Aggiornamento',
  },
  {
    id: EBackofficeUserPermission.LOGIN,
    description: 'Login',
  },
];

export interface IUserRoleInfo {
  id: EUserRole;
  type: EUserType;
  description: string;
  permissions?: { [K in EBackofficeResource]?: EBackofficeUserPermission[] };
}

export const USER_ROLES: IUserRoleInfo[] = [
  {
    id: EUserRole.SUPER_ADMIN,
    type: EUserType.ADMIN_USER,
    description: 'Super Admin',
    permissions: {
      [EBackofficeResource.ORGANIZATIONS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ADMIN_USERS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_ACCOUNTS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_LOYALTY_CARDS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_LOYALTY_CARDS_TRANSACTIONS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_STORES]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_USERS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_CAMPAIGNS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_GIFTCARDS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_GIFTCARDS_TRANSACTIONS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_GIFTCARD_CODE]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_GIFTCARD_UNLOCK]: [EBackofficeUserPermission.UPDATE],
      [EBackofficeResource.ORGANIZATION_NEWS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_ORDERS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_REWARDS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_SETTINGS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_LOGS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_CARDS_TYPE]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_MEDIAS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_FLYERS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_ADMINISTRATION_SETTINGS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_NOTIFICATIONS]: [EBackofficeUserPermission.MANAGE],
    },
  },
  {
    id: EUserRole.HELP_DESK,
    type: EUserType.ADMIN_USER,
    description: 'Help Desk',
    permissions: {
      [EBackofficeResource.ORGANIZATIONS]: [
        EBackofficeUserPermission.CREATE,
        EBackofficeUserPermission.READ,
        EBackofficeUserPermission.UPDATE,
      ],
      [EBackofficeResource.ORGANIZATION_USERS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ADMIN_USERS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_STORES]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_LOYALTY_CARDS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_ACCOUNTS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_REWARDS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_ORDERS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_CAMPAIGNS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_GIFTCARDS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_GIFTCARDS_TRANSACTIONS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_GIFTCARD_CODE]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_GIFTCARD_UNLOCK]: [EBackofficeUserPermission.UPDATE],
      [EBackofficeResource.ORGANIZATION_NEWS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_SETTINGS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_CARDS_TYPE]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_LOYALTY_CARDS_TRANSACTIONS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_LOGS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_MEDIAS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_FLYERS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_ADMINISTRATION_SETTINGS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_NOTIFICATIONS]: [EBackofficeUserPermission.MANAGE],
    },
  },
  //
  {
    id: EUserRole.ORGANIZATION_MANAGER,
    type: EUserType.ORGANIZATION_USER,
    description: 'Amministratore',
    permissions: {
      [EBackofficeResource.ORGANIZATION_ACCOUNTS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_LOYALTY_CARDS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_STORES]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_CAMPAIGNS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_GIFTCARDS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_GIFTCARDS_TRANSACTIONS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_GIFTCARD_CODE]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_NEWS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_ORDERS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_REWARDS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_LOYALTY_CARDS_TRANSACTIONS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_SETTINGS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_USERS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_LOGS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_CARDS_TYPE]: [
        EBackofficeUserPermission.READ,
        EBackofficeUserPermission.UPDATE,
      ],
      [EBackofficeResource.ORGANIZATION_MEDIAS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_FLYERS]: [EBackofficeUserPermission.MANAGE],
      [EBackofficeResource.ORGANIZATION_NOTIFICATIONS]: [EBackofficeUserPermission.MANAGE],
    },
  },
  {
    id: EUserRole.STORE_OWNER,
    type: EUserType.ORGANIZATION_USER,
    description: 'Proprietario negozi',
    permissions: {
      [EBackofficeResource.ORGANIZATION_ACCOUNTS]: [
        EBackofficeUserPermission.CREATE,
        EBackofficeUserPermission.READ,
        EBackofficeUserPermission.UPDATE,
      ],
      [EBackofficeResource.ORGANIZATION_LOYALTY_CARDS]: [
        EBackofficeUserPermission.CREATE,
        EBackofficeUserPermission.READ,
        EBackofficeUserPermission.UPDATE,
      ],
      [EBackofficeResource.ORGANIZATION_LOYALTY_CARDS_TRANSACTIONS]: [
        EBackofficeUserPermission.CREATE,
        EBackofficeUserPermission.UPDATE,
        EBackofficeUserPermission.READ,
      ],
      [EBackofficeResource.ORGANIZATION_CAMPAIGNS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_GIFTCARDS]: [
        EBackofficeUserPermission.CREATE,
        EBackofficeUserPermission.READ,
        EBackofficeUserPermission.UPDATE,
      ],
      [EBackofficeResource.ORGANIZATION_GIFTCARDS_TRANSACTIONS]: [
        EBackofficeUserPermission.CREATE,
        EBackofficeUserPermission.READ,
        EBackofficeUserPermission.UPDATE,
      ],
      [EBackofficeResource.ORGANIZATION_GIFTCARD_CODE]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_ORDERS]: [
        EBackofficeUserPermission.CREATE,
        EBackofficeUserPermission.READ,
        EBackofficeUserPermission.UPDATE,
      ],
      [EBackofficeResource.ORGANIZATION_REWARDS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_CARDS_TYPE]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_STORES]: [EBackofficeUserPermission.READ],
    },
  },
  {
    id: EUserRole.STORE_MANAGER,
    type: EUserType.ORGANIZATION_USER,
    description: 'Responsabile negozio',
    permissions: {
      [EBackofficeResource.ORGANIZATION_LOYALTY_CARDS]: [
        EBackofficeUserPermission.CREATE,
        EBackofficeUserPermission.READ,
        EBackofficeUserPermission.UPDATE,
      ],
      [EBackofficeResource.ORGANIZATION_LOYALTY_CARDS_TRANSACTIONS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_CAMPAIGNS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_GIFTCARDS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_GIFTCARDS_TRANSACTIONS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_ORDERS]: [
        EBackofficeUserPermission.CREATE,
        EBackofficeUserPermission.READ,
        EBackofficeUserPermission.UPDATE,
      ],
      [EBackofficeResource.ORGANIZATION_REWARDS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_ACCOUNTS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_STORES]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_CARDS_TYPE]: [EBackofficeUserPermission.READ],
    },
  },
  {
    id: EUserRole.STORE_CLERK,
    type: EUserType.ORGANIZATION_USER,
    description: 'Commesso negozio',
    permissions: {
      [EBackofficeResource.ORGANIZATION_LOYALTY_CARDS]: [
        EBackofficeUserPermission.CREATE,
        EBackofficeUserPermission.READ,
        EBackofficeUserPermission.UPDATE,
      ],
      [EBackofficeResource.ORGANIZATION_LOYALTY_CARDS_TRANSACTIONS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_CAMPAIGNS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_GIFTCARDS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_GIFTCARDS_TRANSACTIONS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_ORDERS]: [
        EBackofficeUserPermission.READ,
        EBackofficeUserPermission.UPDATE,
      ],
      [EBackofficeResource.ORGANIZATION_REWARDS]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_STORES]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_CARDS_TYPE]: [EBackofficeUserPermission.READ],
      [EBackofficeResource.ORGANIZATION_ACCOUNTS]: [EBackofficeUserPermission.READ],
    },
  },
];
