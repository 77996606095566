import { ENotificationStatus, ENotificationType } from '../constants';
import { IMongodbDocument } from './mongodb';
import { IOrganizationEntityDocument } from './organizations';

export interface INotification extends IMongodbDocument, IOrganizationEntityDocument {
  title: string;
  message: string;
  type: ENotificationType;
  status?: ENotificationStatus;
  sendingDate?: string;
  scheduledDate?: string;
}
