import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbCardModule,
  NbSpinnerModule,
  NbBadgeModule,
  NbAlertModule,
  NbAutocompleteModule,
  NbFormFieldModule,
  NbInputModule,
  NbListModule,
  NbTabsetModule,
  NbTreeGridModule,
  NbDialogModule,
  NbProgressBarModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import { PaginationModule } from 'ngx-bootstrap/pagination';

import {
  BackButtonComponent,
  BadgeActiveComponent,
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  TableListFooterComponent,
  TableListHeaderComponent,
  TinyMCEComponent,
  AppUpdateAlertComponent,
  StoresTypeAhead,
  ConsentsItemListComponent,
  MediaPickerComponent,
  MediaPreviewComponent,
  AccountsTypeAhead,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  OrderStatusPipe,
  NewsStatusPipe,
  GiftCardStatusPipe,
  CardTransactionStatusPipe,
  GiftCardTransactionTypePipe,
  GiftCardTransactionStatusPipe,
  CardTransactionTypePipe,
  CampaignStatusPipe,
  AgreementTypePipe,
  RewardTypePipe,
  AccountActionPipe,
  FileBitesPipe,
  FlyerStatusPipe,
  orderDeliveryStatusPipe,
  NotificationStatusPipe,
} from './pipes';
import { OneColumnLayoutComponent, ThreeColumnsLayoutComponent, TwoColumnsLayoutComponent } from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';

import { UserPermissionPipe, UserResourcePipe, UserRolePipe } from './pipes/user-role.pipe';
import { GenderDescriptionPipe } from './pipes/gender-description.pipe';
import { hasPermissionDirective } from './directives/hasPermission.directive';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbCardModule,
  NbSpinnerModule,
  NbBadgeModule,
  NbEvaIconsModule,
  NbAlertModule,
  NbAutocompleteModule,
  NbFormFieldModule,
  NbAutocompleteModule,
  FormsModule,
  ReactiveFormsModule,
  NbInputModule,
  NbListModule,
  NbTabsetModule,
  NbTreeGridModule,
  NbDialogModule,
  NbProgressBarModule,
];

const NGX_MODULES = [PaginationModule];

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  TableListFooterComponent,
  TableListHeaderComponent,
  BadgeActiveComponent,
  BackButtonComponent,
  AppUpdateAlertComponent,
  StoresTypeAhead,
  ConsentsItemListComponent,
  MediaPickerComponent,
  MediaPreviewComponent,
  AccountsTypeAhead,
  // ResourcesListItemsComponent,
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  GenderDescriptionPipe,
  UserRolePipe,
  OrderStatusPipe,
  NewsStatusPipe,
  GiftCardStatusPipe,
  CardTransactionStatusPipe,
  GiftCardTransactionTypePipe,
  GiftCardTransactionStatusPipe,
  CardTransactionTypePipe,
  CampaignStatusPipe,
  AgreementTypePipe,
  RewardTypePipe,
  UserResourcePipe,
  UserPermissionPipe,
  AccountActionPipe,
  FileBitesPipe,
  FlyerStatusPipe,
  orderDeliveryStatusPipe,
  NotificationStatusPipe,
];

const DIRECTIVES = [hasPermissionDirective];

@NgModule({
  imports: [CommonModule, FormsModule, ...NB_MODULES, ...NGX_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS, ...DIRECTIVES],
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME]
        ).providers,
      ],
    };
  }
}
