export enum ESettingsName {
  GENERAL = 'general',
  CARDS = 'cards',
  ADMINISTRATION = 'administration',
  GRAPHICS = 'graphics',
}

export enum EIntegrationCodes {
  SISTORE = 'sistore',
  PRASSI_MAGIC = 'prassiMagic',
  // OTHER = "other",
  // UNKNOW = "unknow",
}

export interface IIntegrationInfo {
  code: EIntegrationCodes;
  name: string;
  description: string;
}

export const IntegrationsList: IIntegrationInfo[] = [
  {
    code: EIntegrationCodes.SISTORE,
    name: 'Gestionale Siretail Si Store',
    description:
      'Importa automaticamente le tessere e le transazioni di acquisto di tutti i punti vendita del gestionale Si Store by SIRETAIL.',
  },
  {
    code: EIntegrationCodes.PRASSI_MAGIC,
    name: 'Gestionale Prassi MA.G.I.C.',
    description:
      'Importa automaticamente le tessere e le transazioni di acquisto di tutti i punti vendita del gestionale MA.G.I.C. by PRASSI.',
  },
];
