import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

import { IMedia } from '@loyx/common';

import { ItemsListPageBaseComponent } from '../../../@core/utils/items-list-page-base.component';

import { MediasService } from '../medias.service';

@Component({
  selector: 'media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss'],
})
export class MediaGalleryComponent extends ItemsListPageBaseComponent<IMedia> {
  @Input() imageFromParent;
  @Input() multiSelection: boolean = false;
  @Input() defaultPerpage: number = 12;
  @Input() selectionMode: boolean = true;
  @Input() contentType: string[] = [];

  @Output() selectedImageOutput = new EventEmitter<IMedia | IMedia[]>();
  images: IMedia[] = [];
  selectedImage: IMedia = null;
  selectedImages: IMedia[] = [];

  constructor(private mediasService: MediasService, toastr: NbToastrService) {
    super(mediasService, toastr);
  }

  override async ngOnInit() {
    this.pagination = {
      page: 1,
      perpage: this.defaultPerpage,
    };

    this.loadMedias();
    this.restoreSelectedImagesFromStorage();
    if (this.imageFromParent instanceof Array) {
      this.selectedImages = this.imageFromParent;
    } else this.selectedImage = this.imageFromParent;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.imageFromParent && changes.imageFromParent.currentValue) {
      this.selectedImage = changes.imageFromParent.currentValue;
      if (!this.multiSelection) {
        this.selectedImages = [this.imageFromParent];
      }
    }
  }

  isScreenSmall() {
    return window.innerWidth < 576;
  }

  async loadMedias() {
    this.mediasService
      .queryAll({
        filters: {
          contentType: this.contentType,
        },
        pagination: this.pagination,
      })
      .subscribe((res) => {
        this.images = res.data;
        this.totalItems = res.totalItems;
        this.restoreSelectedImages();
      });
  }

  restoreSelectedImages() {
    if (!this.multiSelection && this.selectedImage) {
      const selected = this.images.find((img) => img._id === this.selectedImage._id);
      if (selected) {
        this.selectedImage = selected;
      }
    } else {
      this.selectedImages = this.selectedImages.map((selected) => {
        return this.images.find((img) => img._id === selected._id) || selected;
      });
    }
  }

  saveSelectedImagesToStorage() {
    const selectedImageIds = this.selectedImages.map((img) => img._id);
    localStorage.setItem('selectedImages', JSON.stringify(selectedImageIds));
  }

  restoreSelectedImagesFromStorage() {
    const selectedImageIds = JSON.parse(localStorage.getItem('selectedImages') || '[]');
    this.selectedImages = selectedImageIds
      .map((id) => this.images.find((img) => img._id === id))
      .filter((img) => img);
  }

  selectImage(event: Event, image: IMedia) {
    if (!this.selectionMode) return;
    event.preventDefault();

    if (this.multiSelection == true) {
      if (this.selectedImage) {
        if (this.selectedImage instanceof Array) this.selectedImages = this.selectedImage;
        else this.selectedImages.push(this.selectedImage);
        this.selectedImage = null;
      }
      const index = this.selectedImages.findIndex((img) => img._id === image._id);
      if (index > -1) {
        this.selectedImages.splice(index, 1);
        if (this.selectedImages.length == 0) this.selectedImageOutput.emit();
      } else {
        this.selectedImages.push(image);
        this.saveSelectedImagesToStorage();
        this.selectedImageOutput.emit(this.selectedImages);
      }
    } else {
      if (this.selectedImage === image) {
        this.selectedImage = null;
        this.selectedImages = [];
        this.selectedImageOutput.emit();
      } else {
        this.selectedImage = image;
        this.selectedImages = [image];
        this.saveSelectedImagesToStorage();
        this.selectedImageOutput.emit(this.selectedImage);
      }
    }
  }

  override onDeleteItemSuccess() {
    this.toastr.success('Risorsa eliminata con successo', 'Yeah!', { duration: 3000 });
    this.loadMedias();
  }

  isSelected(image: IMedia): boolean {
    if (!Array.isArray(this.selectedImage) && !this.multiSelection)
      return this.selectedImage?._id === image._id;
    else if (this.multiSelection) return this.selectedImages.some((img) => img._id === image._id);
  }
}
