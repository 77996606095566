export enum ENotificationStatus {
  PENDING = 'PENDING',
  READY = 'READY',
  SENT = 'SENT',
}

export enum ENotificationType {
  MANUAL = 'MANUAL',
  SCHEDULED = 'SCHEDULED',
}

export const NotificationStatusDescriptions = [
  {
    id: ENotificationStatus.PENDING,
    description: 'In attesa',
  },
  {
    id: ENotificationStatus.READY,
    description: 'Pronta',
  },
  {
    id: ENotificationStatus.SENT,
    description: 'Inviata',
  },
];
