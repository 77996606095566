import { ENotificationStatus, ENotificationType } from '../constants';
import { DbOrganizationResourceEntity } from './db.entity';

export class NotificationEntity extends DbOrganizationResourceEntity<NotificationEntity> {
  title: string;
  message: string;
  type: ENotificationType;
  status?: ENotificationStatus;
  sendingDate?: string;
  scheduledDate?: string;
}
