export enum ECardStatus {
  /** SCONOSCIUTO */
  UNKNOWN = '',
  /** ATTIVA */
  ACTIVE = 'ACTIVE',
  /** NON ATTIVA */
  INACTIVE = 'INACTIVE',
  /** IN ATTESA */
  PENDING = 'PENDING',
  /** SCADUTA */
  EXPIRED = 'EXPIRED',
  /** ANNULLATA */
  CANCELED = 'CANCELED',
}

export enum ECardSummaryMovementType {
  UNKNOWN = '',
  PURCHASE = 'PURCHASE',
  STORE_PURCHASE = 'STORE_PURCHASE',
  POINTS_ADD = 'POINTS_ADD',
  POINTS_REMOVE = 'POINTS_REMOVE',
  POINTS_TRANSFER = 'POINTS_TRANSFER',
  REWARD_REQUEST = 'REWARD_REQUEST',
  REFUND = 'REFUND',
}

export const CardSummaryMovementTypeDescription = {
  [ECardSummaryMovementType.PURCHASE]: 'Acquisto',
  [ECardSummaryMovementType.STORE_PURCHASE]: 'Acquisto negozio',
  [ECardSummaryMovementType.POINTS_ADD]: 'Aggiunta punti',
  [ECardSummaryMovementType.POINTS_REMOVE]: 'Aggiunta punti',
  [ECardSummaryMovementType.POINTS_TRANSFER]: 'Transferimento punti',
  [ECardSummaryMovementType.REWARD_REQUEST]: 'Richiesta premio',
  [ECardSummaryMovementType.REFUND]: 'Rimborso',
};

export enum ECardSummaryMovementTrigger {
  /** Movimento da transazione */
  TRANSACTION = 'TRANSACTION',
  /** Movimento da evento */
  EVENT = 'EVENT',
  /** Movimento da evento personalizzato */
  // CUSTOM_EVENT = "CUSTOM_EVENT",
  // SYSTEM_EVENT = "SYSTEM_EVENT",
  // CAMPAIGN_EVENT = "CAMPAIGN_EVENT",
}

export enum ECardTransactionType {
  /** Acquisto */
  PURCHASE = 'PURCHASE',
  /** Aggiunta/rimozione punti campagna */
  POINTS = 'POINTS',
  /** TRANSFERIMENTO PUNTI */
  POINTS_TRANSFER = 'POINTS_TRANSFER',
  /** Acquisto in un punto vendita */
  // STORE_PURCHASE = "STORE_PURCHASE",
  REWARD_REQUEST = 'REWARD',
  /** RIMBORSO ORDINE */
  REFUND = 'REFUND',
}

export const CardTransactionType = [
  {
    id: ECardTransactionType.POINTS,
    description: 'Movimento Punti',
  },
  {
    id: ECardTransactionType.POINTS_TRANSFER,
    description: 'Trasferimento Punti',
  },
  {
    id: ECardTransactionType.PURCHASE,
    description: 'Acquisto',
  },
  {
    id: ECardTransactionType.REWARD_REQUEST,
    description: 'Richiesta Premio',
  },
  {
    id: ECardTransactionType.REFUND,
    description: 'Rimborso',
  },
];

export enum ECardTransactionSource {
  /** Transazione inserita manualmente su backoffice */
  BACKOFFICE = 'BACKOFFICE',
  /** Transazione inserita con utilizzo delle integrazioni api */
  API = 'API',
  /** Transazione inserita con l'utilizzo dell'app */
  APP = 'APP',
}

export enum ECardTransactionStatus {
  /** RIFIUTATO */
  REJECTED = 'REJECTED',
  /** IN CODA */
  QUEUE = 'QUEUE',
  /** IN ATTESA */
  PENDING = 'PENDING',
  /** APPROVATO */
  APPROVED = 'APPROVED',
  /** IN CORSO */
  PROCESSING = 'PROCESSING',
  /** PROCESSATA */
  PROCESSED = 'PROCESSED',
}

export const CardTransactionStatus = [
  {
    id: ECardTransactionStatus.REJECTED,
    description: 'Rifiutata',
  },
  {
    id: ECardTransactionStatus.QUEUE,
    description: 'In coda',
  },
  {
    id: ECardTransactionStatus.PENDING,
    description: 'In attesa',
  },
  {
    id: ECardTransactionStatus.APPROVED,
    description: 'Approvata',
  },
  {
    id: ECardTransactionStatus.PROCESSING,
    description: 'In corso',
  },
  {
    id: ECardTransactionStatus.PROCESSED,
    description: 'Processata',
  },
];
